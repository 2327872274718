import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import ToHtml from '../components/html';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Gimg } from '../components/gimg';
import { colors, animation, mediaDesktop, mediaFHD } from '../components/setting';
import { Container } from '../components/base';
import { SubHero } from '../layout/subHero';
import { Page } from '../types';
import { Category } from '../layout/category';
import zoomIcon from '../assets/zoom-in.svg';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { settings } from 'cluster';

type DataNode = {
	datoCmsBlog: Page;
	datoCmsPage: Page;
	allDatoCmsPage: { nodes: Page[] };
};

export default (data: { data: DataNode }) => {
	console.log('blog data', data);
	const node = data.data.datoCmsPage;
	const [showGallery, setShowGallery] = useState(false);
	const [indexGallery, setIndexGallery] = useState(0);

	const styleFn = (styleObj: any) => ({ ...styleObj, zIndex: 100 });

	const galleryArray = node.gallery.map(el => ({ src: el.url }));

	// export default (data) => {
	return (
		<>
			<HelmetDatoCms seo={node.seoMetaTags} />
			<SubHero data={node} />
			<Container>
				{node.content &&
					(data.data.allDatoCmsPage.nodes.length === 0 ? (
						<Col>
							<Box>
								<ToHtml tags={node.content} />
								{node.files.length > 0 ? (
									<div>
										<h4>Katalogi producentów:</h4>
										{node.files.map((el: any, i: number) => (
											<FileLink href={el.url} target="_blank">
												{el.title}
											</FileLink>
										))}
									</div>
								) : (
									''
								)}
							</Box>
							<MarkList>
								{node.marks.map((el: any, i: number) => (
									<MarkItem key={i}>
										<img src={el.img.url} alt={el.name} />
									</MarkItem>
								))}
							</MarkList>
						</Col>
					) : (
						<Category list={data.data.allDatoCmsPage.nodes} pageInfo={node} />
					))}

				<Gallery>
					{node.gallery.map((el: any, i: number) => (
						<GalleryItem
							key={i}
							href={el.url}
							onClick={e => {
								setIndexGallery(i);
								setShowGallery(true);
								e.preventDefault();
							}}>
							<Gimg
								fixed={el.fixed}
								style={{
									width: '100%',
									height: '100%',
									display: 'block',
								}}
								alt={el.alt}
							/>
							<Zoom>
								<img src={zoomIcon} alt="Powiększ" />
							</Zoom>
						</GalleryItem>
					))}
				</Gallery>

				<ModalGateway>
					{showGallery ? (
						<Modal
							onClose={() => setShowGallery(false)}
							styles={{ blanket: styleFn, positioner: styleFn }}>
							<Carousel
								views={galleryArray}
								currentIndex={indexGallery}
								closeOnBackdropClickBoolean={true}
							/>
						</Modal>
					) : null}
				</ModalGateway>
			</Container>
		</>
	);
};
const Col = styled.div`
	@media ${mediaDesktop} {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}
`;

const FileLink = styled.a`
	&::after {
		content: 'PDF';
		font-size: 11px;
		font-weight: bold;
		letter-spacing: -1px;
		line-height: 12px;
		width: 25px;
		text-align: right;
		color: #ccc;
		position: relative;
		top: -2px;
		display: inline-block;
	}
	border: 1px solid #fff;
	display: inline-block;
	border-radius: 0.5rem;
	text-transform: uppercase;
	padding: 5px 10px;
	background: #fff;
	margin-bottom: 0.7rem;
	margin-right: 0.7rem;
	&:hover {
		border-color: ${colors.brand};
	}
`;
const Lightbox = styled.div`
	z-index: 9999;
`;

const Gallery = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 6rem -1rem;
`;
const GalleryItem = styled.a`
	display: block;
	width: 100%;
	height: 300px;
	position: relative;
	padding: 1rem;

	&::after {
		/* transition: opacity ${animation.fast}, transform ${animation.fast}; */
		transition: opacity ${animation.fast};
		content: '';
		background: ${colors.brand};
		position: absolute;
		opacity: 0;
		z-index: 3;
		top: 1rem;
		right: 1rem;
		bottom: 1rem;
		left: 1rem;
		/* transform: scale(0.7); */
	}
	&:hover::after {
		/* transform: scale(1); */
		opacity: 0.5;
	}

	@media (min-width: 500px) {
		width: 50%;
		height: 250px;
	}

	@media ${mediaDesktop} {
		width: 33.33%;
		height: 300px;
	}

	@media ${mediaFHD} {
		width: 33.33%;
		height: 400px;
	}
`;

const Zoom = styled.div`
	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	height: 7rem;
	width: 7rem;
	opacity: 0;
	transform: scale(0.8);
	transition: opacity ${animation.elastic}, transform ${animation.elastic};
	a:hover & {
		opacity: 1;
		transform: scale(1);
	}
	a:active & {
		opacity: 1;
		transition: opacity ${animation.elastic}, transform ${animation.fast};
		transform: scale(1.1);
	}
`;

const Box = styled.div`
	@media ${mediaDesktop} {
		width: 50%;
		padding: 2rem 6rem 5rem 0;
		margin-right: 50px;
		background: ${colors.gray};
		position: relative;
		&::after {
			content: '';
			background: ${colors.gray};
			position: absolute;
			top: 0;
			right: 100%;
			bottom: 0;
			width: 50vh;
		}
	}
`;

const MarkList = styled.div`
	@media ${mediaDesktop} {
		width: 50%;
		margin-left: 50px;
	}
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
`;

const MarkItem = styled.div`
	width: 220px;
	max-width: calc(45% - 20px);
	margin: 10px;
	transition: filter ${animation.slow};
	filter: saturate(0) brightness(1.1);
	&:hover {
		filter: saturate(1) brightness(1);
	}
`;
export const query = graphql`
	query BlogPost($slug: String) {
		datoCmsPage(slug: { eq: $slug }) {
			title
			slug
			content
			category {
				slug
				title
			}
			img {
				fixed(width: 1920, imgixParams: { fm: "jpg", auto: "compress" }) {
					...GatsbyDatoCmsFixed
				}
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			marks {
				name
				id
				img {
					url
				}
			}
			gallery {
				alt
				url
				fixed(width: 537, imgixParams: { fm: "jpg", auto: "compress" }) {
					...GatsbyDatoCmsFixed
				}
			}
			files {
				alt
				url
				title
			}
		}
		allDatoCmsPage(filter: { category: { slug: { eq: $slug } } }) {
			nodes {
				title
				slug
				category {
					slug
				}
				img {
					fixed(width: 1670, imgixParams: { fm: "jpg", auto: "compress" }) {
						...GatsbyDatoCmsFixed
					}
				}
			}
		}
	}
`;
