import React from 'react';
import styled from 'styled-components';
import { Gimg } from '../components/gimg';
import { Link } from 'gatsby';
import { Page } from '../types';
import { colors } from '../components/setting';

type SubHeroProps = {
	data: Page;
};

export const SubHero = (data: SubHeroProps) => {
	const item = data.data;

	console.log('item sub hero', item);

	return (
		<Banner img={item.img ? true : false}>
			{item.img && (
				<Foto>
					<Gimg
						fixed={item.img.fixed}
						style={{
							width: '100%',
							height: '100%',
							display: 'block',
						}}
					/>
				</Foto>
			)}
			<Content>
				<h1
					className={(() =>
						'mb0 header--center ' +
						(item.img ? ' header--disabled' : ' header--lineDown'))()}>
					{item.title}
				</h1>
				<Crumbs>
					<Link to="/">Strona Główna</Link>
					{item.category && <Link to={item.category.slug}>{item.category.title}</Link>}
					<span>{item.title}</span>
				</Crumbs>
			</Content>
		</Banner>
	);
};

const Banner = styled.div<{ img: boolean }>`
	position: relative;
	height: ${p => (p.img ? 340 : 185)}px;
	margin-bottom: 4rem;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	padding-top: 20px;
	margin-top: -20px;
	background: ${p => (p.img ? colors.brandDark : 'white')};
	color: ${p => (p.img ? 'white' : 'black')};
`;

const Crumbs = styled.div`
	padding: 0;
	font-size: 1.2rem;
	font-weight: 500;
	text-transform: uppercase;
	margin-top: -1.5rem;
	& a,
	& span {
		color: inherit;
		padding: 0 10px;
		display: inline-block;
		position: relative;
	}
	& a:hover {
		color: inherit;
		text-decoration: underline;
	}
	& a + a::after,
	& a + span::after {
		content: '/';
		position: absolute;
		right: 100%;
		top: 0;
		width: 10px;
		text-align: center;
		margin-right: -5px;
	}
`;

const Foto = styled.div`
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	position: absolute;
	z-index: 1;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	opacity: 0.4;
`;

const Content = styled.div`
	position: relative;
	z-index: 5;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;
