import React from 'react';
import styled from 'styled-components';
import ToHtml from '../components/html';
import { Gimg } from '../components/gimg';
import { Link } from 'gatsby';
import { animation, colors, mediaDesktop } from '../components/setting';
import { Page } from '../types';

export const Category = (props: { list: Page[]; pageInfo: Page }) => (
	<List>
		{console.log('nodes:', props)}

		<Desc>
			<ToHtml tags={props.pageInfo.content} />
		</Desc>

		{props.list.map((item: Page) => (
			<Item key={item.slug}>
				<Link to={item.category.slug + '/' + item.slug + '/'}>
					{(() =>
						item.img ? (
							<Gimg
								fixed={item.img.fixed}
								style={{
									width: '100%',
									height: '480px',
									display: 'block',
								}}
							/>
						) : (
							'No foto'
						))()}
					<Title className="header--center">{item.title}</Title>
				</Link>
			</Item>
		))}
	</List>
);

const Desc = styled.div`
	@media ${mediaDesktop} {
		width: 50%;
		padding-right: 10rem;
		/* padding-bottom: 20rem; */
	}
`;

const List = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	@media ${mediaDesktop} {
		justify-content: space-between;
	}
	/* &::before {
        content: "";
        width: calc(50% - 50px);
        padding-right: 50px;
    } */
`;
const Item = styled.div`
	width: 100%;
	margin-top: 60px;
	@media ${mediaDesktop} {
		width: calc(50% - 6rem);
		margin-top: 10rem;
		position: relative;

		&:nth-child(odd) {
			margin-top: -5rem;
		}
	}

	& .image {
		background: ${colors.brandDark};
	}

	& .image img {
		transition: transform ${animation.fast}, opacity ${animation.fast} !important;
		transform: scale(1.05);
		opacity: 1;
	}
	& a:hover .image img {
		transform: scale(1);
		opacity: 0.5 !important;
	}
`;

const Title = styled.h4`
	margin: -48px 0 0 0;
	@media ${mediaDesktop} {
		margin: -48px 48px 0 48px;
	}
	padding: 25px;
	position: relative;
	z-index: 5;
	color: #000;
	transition: color ${animation.fast};

	a:hover &,
	a:focus & {
		color: ${colors.brand};
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		z-index: -1;
		transform: scale(1);
		transition: transform ${animation.fast};
	}
	a:hover &::before,
	a:focus &::before {
		transform: scale(1.05);
	}

	&::after {
		transition: transform ${animation.fast};
		transform: scaleX(0.8);
	}
	a:hover &::after,
	a:focus &::after {
		transform: scaleX(1);
	}
`;
